import React from 'react';
import { Bar } from 'react-chartjs-2';
import Box from 'elements/Box';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
} from 'chart.js';

// Registrar los componentes necesarios de Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

// Definir la interfaz para los datos de los recolectores
interface CollectorData {
  user_id: number;
  user_name: string;
  total_collected: number;
}

// Definir los tipos de datos para el gráfico
type DataType = ChartData<'bar', number[], string>;
type OptionsType = ChartOptions<'bar'>;

// Función auxiliar para asignar colores a cada usuario
function getColor(index: number): string {
  const colors = [
    'rgba(75, 192, 192, 0.8)', // Verde
    'rgba(54, 162, 235, 0.8)', // Azul
    'rgba(255, 206, 86, 0.8)', // Amarillo
    'rgba(255, 99, 132, 0.8)', // Rojo
    'rgba(153, 102, 255, 0.8)', // Morado
  ];
  return colors[index % colors.length];
}

export default function DailyCollectors(props: any) {
  const { data } = props;

  // Ordenar los datos para que el que más recolectó esté arriba
  const sortedData = data.sort((a: CollectorData, b: CollectorData) => a.total_collected - b.total_collected);

  // Preparar los datos para el gráfico
  const chartData: DataType = {
    labels: ['Hoy'],
    datasets: sortedData.map((collector: CollectorData, index: number) => ({
      label: collector.user_name,
      data: [collector.total_collected],
      backgroundColor: getColor(index),
      stack: 'stack1',
    })),
  };

  // Opciones de configuración del gráfico para ocupar todo el espacio del contenedor
  const options: OptionsType = {
    responsive: true,
    maintainAspectRatio: false, // Permitir que el gráfico ocupe el 100% del contenedor
    plugins: {
      legend: {
        position: 'right',
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
    },
    scales: {
      x: {
        stacked: true,
        display: false, // Ocultar el eje X
        grid: {
          display: false, // Ocultar las líneas de la cuadrícula del eje X
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        display: false, // Ocultar el eje Y
        grid: {
          display: false, // Ocultar las líneas de la cuadrícula del eje Y
        },
      },
    },
  };

  return (
    <Box title="Recaudo del día" style={{ height: '420px' }}>
      <div style={{ width: '100%', height: '100%' }}>
        <Bar data={chartData} options={options} />
      </div>
    </Box>
  );
}
