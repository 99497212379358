import React, { useEffect, useState } from 'react';
import { Dialog, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Checkbox } from '@mui/material';
import { ApiRequest } from "GlobalFunctions";
import { useSnackbar } from "notistack";
import PaymentDialog from './PaymentDialog';

export default function InvoiceListDialog(props: any) {
  const { open, setOpen, client, setLoading } = props;

  const { enqueueSnackbar } = useSnackbar();
  const [invoices, setInvoices] = useState<any[]>([]);
  const [selectedInvoices, setSelectedInvoices] = useState<any[]>([]);
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);

  const fetchInvoices = async () => {
    if (client) {
      setLoading(true);

      await ApiRequest({
        url: `/invoices/pendingInvoices`,
        method: "get",
        headers: {
          ltkn: localStorage.getItem("ltkn"),
        },
        query: {
          client_id: client.client_id,
          full_list: true,
        },
        setResponse: (response: any) => {
          if (response && response.list) {
            setInvoices(response.list);
          } else {
            enqueueSnackbar("No se encontraron facturas para este cliente.", { variant: "warning" });
            setInvoices([]);
          }
        },
      });

      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      fetchInvoices();
    }
  }, [open]);

  const handleCheckboxChange = (invoice: any) => {
    const isSelected = selectedInvoices.includes(invoice);
    const updatedSelection = isSelected
      ? selectedInvoices.filter((i: any) => i !== invoice)
      : [...selectedInvoices, invoice];
    setSelectedInvoices(updatedSelection);
  };

  const handlePaymentClick = () => {
    setPaymentDialogOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedInvoices([]); // Limpiar la lista de facturas seleccionadas al cerrar el diálogo
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <div className="DialogContainer">
          <h3>Facturas de {client?.client_name}</h3>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell> {/* Columna para los checkboxes */}
                  <TableCell>ID Factura</TableCell>
                  <TableCell>Fecha</TableCell>
                  <TableCell>Monto</TableCell>
                  <TableCell>Estado</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoices.length > 0 ? (
                  invoices.map((invoice, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Checkbox
                          checked={selectedInvoices.includes(invoice)}
                          onChange={() => handleCheckboxChange(invoice)}
                        />
                      </TableCell>
                      <TableCell>{invoice.invoice_id}</TableCell>
                      <TableCell>{invoice.date}</TableCell>
                      <TableCell>{invoice.amount}</TableCell>
                      <TableCell>{invoice.status}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      No hay facturas disponibles para este cliente.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="dialogButtons">
            <Button onClick={handleClose} variant="outlined">
              Cerrar
            </Button>
            <Button
              onClick={handlePaymentClick}
              variant="contained"
              color="primary"
              disabled={selectedInvoices.length === 0} // Deshabilita el botón si no hay facturas seleccionadas
            >
              Pagar
            </Button>
          </div>
        </div>
      </Dialog>

      {/* Diálogo para el formulario de pago */}
      <PaymentDialog
        open={paymentDialogOpen}
        setOpen={setPaymentDialogOpen}
        selectedInvoices={selectedInvoices}
        client={client}
        getInvoices={fetchInvoices}
        setSelectedInvoices={setSelectedInvoices}
        closeInvoiceListDialog={handleClose}
      />
    </>
  );
}
