import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Button, Chip, CircularProgress, Dialog, IconButton, Modal, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ProjectConfig} from 'Global';
import moment from 'moment';
import AlertDialog from 'elements/AlertDialog';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useSnackbar } from 'notistack';

import 'assets/css/main.css'; 
import AdminEditor from './UsersEditor';
import UsersEditor from './UsersEditor';
import EmptyElement from 'elements/EmptyElement';
import { ApiRequest } from 'GlobalFunctions';

export default function UsersList(props: any) {

  const {
    company_id = null,
    is_popup = false
  } = props;

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {setLoading} = props;

  useEffect(() => {
    setLoading(false);
  }, [])
  
  const [user_list, setUserList] = useState<any []>([]);
  const [total_pages, setTotalPages] = useState(0);

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage=parseInt(localStorage.getItem('page') || "1");

  const [page, setPage] = useState(currentPage);
  const category = queryParams.get('category');
  const [refreshList, setRefreshList] = useState(0);

  const [openEditor, setOpenEditor] = useState(false);



  useEffect(() => {
    setLoading(true);
    loadList();
    
  }, [page])

  /*=========================================================
  LOAD LIST
  =========================================================*/
  const loadList = async () => {
    setLoading(true);

    // axios.get(ProjectConfig.api_url+"/users/list",
    // {
    //   headers: {
    //     'ltkn': localStorage.getItem('ltkn')
    //   },
    //   params: {
    //     page: page,
    //     role_id: 2,
    //     company_id: company_id
    //   }
    // })
    // .then((response) => {
    //     console.log("User List ",response.data); 
    //     setUserList(response.data.list)
    //     setTotalPages(response.data.total_pages)
    //     setLoading(false)
    // })
    // .catch((err) => {
    //   console.log(err.response.data);
    // })
    // .finally(() => {
    //   setLoading(false)
    // })

    await ApiRequest({
      url: "/users/list",
      method: "get",
      headers: {
        'ltkn': localStorage.getItem('ltkn')
      },
      query: {
        page: page,
        role_id: 2,
        company_id: company_id
      },
      setResponse: (response: any) => {
        console.log("User List ",response); 
        setUserList(response.list)
        setTotalPages(response.total_pages)
        setLoading(false)
      }
    })
    
    localStorage.setItem('page', page.toString())
}


  /*=========================================================*/



  /*=========================================================
  DELETE ITEM
  =========================================================*/

  const handleDelete = (user_id:number) => {
    setActiveItem(user_id)
    setOpenAlert(true)
  }



  const deleteItem = () => {

    const body = {
      user_id: activeItem
    }

    axios.post(ProjectConfig.api_url+"/users/delete", body)
      .then((response) => {
        console.log(response.data);
        if(response.data.status === "success"){
          enqueueSnackbar('Usuario eliminado con éxito', { variant: 'success' });
          loadList();
        }
        
      })
      .catch((err) => {
        console.log(err.data);
      })
      .then(() => {
        setOpenAlert(false)
      })
  }
  

  /*=========================================================*/

// call global snackbar
  
const [openAlert, setOpenAlert] = useState(false);
const [activeItem, setActiveItem] = useState<any>(null);

  
  return (
      <div id='UsersList' className='ModuleContainer'>
        <div className="ModuleWrapper"> 
          <div className="boxContainerHeader">
            
            <div className="boxContainerTitle">
              <h2 className="h2_title">Usuarios</h2>
              <span className="h2_subtitle">Lista de usuarios registrados</span>
            </div>
            
            <div className="boxContainerActions">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setActiveItem(null)
                setOpenEditor(true)
              }}
              startIcon={<AddIcon />}
            >
              Crear Usuario
            </Button>

            </div>
            
          </div>

          <div id='UsersListIn'>
            
              <Box id='AdminList' className={!is_popup ? 'box100' : 'boxModal'}>

               {user_list.length !== 0? (
              <TableContainer>
              <Table aria-label="sticky table"> 
                <TableHead>
                  <TableRow>
                    <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>NOMBRE / EMAIL</TableCell>
                    <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>TELÉFONO</TableCell>
                    <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>ESTADO</TableCell>
                    <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>PERFIL</TableCell>
                    <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                    {user_list.map((user,i) => (
                      <TableRow 
                        
                        key={i}
                        hover
                        role="checkbox"
                      >
                        <TableCell>
                          <span className='flex_title'>{user.name} {user.last_name}</span>
                          <span className='flex_subtitle'>{user.email}</span>
                        </TableCell>

                      
                        <TableCell>
                          {user.phone}
                        </TableCell>

                        <TableCell>
                          <Chip label={user.is_active ? "Activo" : "Inactivo"} color={user.is_active ? "primary" : "error"} variant="outlined" size='small' />
                        </TableCell>

                        <TableCell>
                          {user.role}
                        </TableCell>

                        <TableCell align="right">

                          <Button 
                            // component={Link}
                            // to={`edit/${user.user_id}`}
                            size="small"
                            variant="contained"
                            color="primary"
                            style={{"marginRight": "10px"}}
                            startIcon={<ModeEditIcon />}
                            onClick={() => {
                              setActiveItem(user.user_id)
                              setOpenEditor(true)
                            }}
                          >
                            Editar
                          </Button>


                          {localStorage.getItem('role_id') === "1" || localStorage.getItem('role_id') === "2" &&
                          <IconButton aria-label="delete"  color="primary" onClick={() => handleDelete(user.user_id)}>
                            <DeleteOutlineOutlinedIcon />
                          </IconButton>
                          }
                      </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            ) : (
              <div className="emptyList">
                <EmptyElement title="No hay usuarios registrados" subtitle="Empieza creando un nuevo usuario" />
              </div>
            )}
            
            {total_pages > 1 && (
            <Pagination 
              count={total_pages} 
              page={page}  
              onChange={(event, value) => {
                setPage(value)
                }} 
              color="primary"
              style={{"marginTop": "20px","alignSelf": "center"}}
            />
            )}
            </Box>
  
          </div>
        </div>

        <AlertDialog
            open={openAlert}
            setOpen={setOpenAlert}
            subtitle="¿Está seguro que desea eliminar este usuario?"
            send={deleteItem}
        />



        <UsersEditor
          setLoading={setLoading}
          openEditor={openEditor} 
          setOpenEditor={setOpenEditor}
          user_id={activeItem} 
          loadList={loadList}
          company_id={company_id}
        />



    </div>
  );
};