import { Button, Dialog, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import Popup from "elements/Popup";

export default function ReportViewer(props: any) {
  const { loading, setLoading, open, setOpen, data, reportName, getReport } = props;
  const { enqueueSnackbar } = useSnackbar();


  /*=======================================
  SWITCH CASE VALUE TO GET TITLE
  =======================================*/
  
  const [title, setTitle] = useState('');
  useEffect(() => {
    switch (reportName) {
      case 'GetCountUnitInventory':
        setTitle('Conteo de inventario unidades');
        break;
      case 'GetCountUnitInventoryWarehouse':
        setTitle('Conteo de inventario x almacenes');
        break;
      case 'GetCountUsersUnit':
        setTitle('Conteo de inventario unidades x usuario');
        break;
      case 'GetCountUsersDifferences':
        setTitle('Diferencias');
        break;
      case 'GetPhysicalCountVersusInventory':
        setTitle('Inventario físico vs sistema');
        break;
      case 'GetMissingAndLeftoverCount':
        setTitle('Inventario faltante vs sobrante');
        break;
      case 'GetPrice':
        setTitle('Inventario faltante vs sobrante x valores');
        break;
      case 'GetStock':
        setTitle('Inventario físico vs stock');
        break;
      case 'GetSimilarCount':
        setTitle('Similares');
        break;
      default:
        setTitle('Reporte');
        break;
    }
  }, [reportName]);
  


  return (
    <>
      <Popup 
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        fullWidth={true}
        maxWidth="lg"
        loading={loading}

      >
        <div className="DialogContainer" style={{ width: 'auto' }}>
          <div className="ModuleWrapper">
            <div className="boxContainerHeader">
              <div className="boxContainerTitle">
                <h2 className="h2_title">{title}</h2>
              </div>
            </div>

            <div className="content">
              {data && data.headers && data.rows && data.rows.length > 0 ? (
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {data.headers.map((header: string) => (
                          <TableCell key={header}>{header}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.rows.map((row: any, index: number) => (
                        <TableRow key={index}>
                          {data.headers.map((header: string) => (
                            <TableCell key={header}>{row[header]}</TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography variant="h6" align="center" style={{ padding: '20px' }}>
                  No hay datos disponibles para mostrar.
                </Typography>
              )}
            </div>
          </div>
        </div>

        <div className="dialogButtons" style={{ display: 'flex', justifyContent: 'space-between', padding: '16px' }}>
          <Button
            onClick={() => setOpen(false)}
            variant="outlined"
            className="button_2"
          >
            Cerrar
          </Button>

          <Button onClick={() => getReport('excel')} variant="contained" className="button_1">Descargar Excel</Button>
          <Button onClick={() => getReport('pdf')} variant="contained" className="button_1">Descargar PDF</Button>
        </div>
      </Popup>
    </>
  );
}
