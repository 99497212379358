import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import MenuButton from 'elements/MenuButton';
import { AiOutlineDashboard } from "react-icons/ai";
import ReceiptIcon from '@mui/icons-material/Receipt';
import ChecklistIcon from '@mui/icons-material/Checklist';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import WidgetsIcon from '@mui/icons-material/Widgets';
import PersonIcon from '@mui/icons-material/Person';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import MapIcon from '@mui/icons-material/Map';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import StarIcon from '@mui/icons-material/Star';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import { HiOutlineBanknotes } from "react-icons/hi2";
import { BsBuildings } from "react-icons/bs";
import { RiAdminLine } from "react-icons/ri";
import { TfiPackage } from "react-icons/tfi";
import { LiaTelegramPlane } from "react-icons/lia";
import { LuUsers2 } from "react-icons/lu";
import 'assets/css/main.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';



const iconMap: any = {
  'SpeedIcon': <AiOutlineDashboard />,
  'ReceiptIcon': <ReceiptIcon />,
  'ChecklistIcon': <ChecklistIcon />,
  'WarehouseIcon': <WarehouseIcon />,
  'WidgetsIcon': <WidgetsIcon />,
  'PersonIcon': <PersonIcon />,
  'CloudSyncIcon': <CloudSyncIcon />,
  'MapIcon': <MapIcon />,
  'LocalShippingIcon': <LocalShippingIcon />,
  'StarIcon': <StarIcon />,
  'AssessmentOutlinedIcon': <AssessmentOutlinedIcon />,
  'AdminPanelSettings': <PersonIcon />,
  'Business': <BsBuildings />,
  'Category': <WidgetsIcon />,
  'Inventory2': <ChecklistIcon />,
  'RocketLaunch': <StarIcon />,
  'SupervisorAccount': <PersonIcon />,
  'PaymentMethods': <HiOutlineBanknotes />,
  'Companies': <BsBuildings />,
  'Admin': <RiAdminLine />,
  'Plans': <TfiPackage />,
  'Notifications': <LiaTelegramPlane />,
  'Users': <LuUsers2 />,
};

export default function MainMenu(props: any) {
  const { permissions } = props;

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [expandedIndex, setExpandedIndex] = useState<number | false>(false);

  const handleAccordionChange = (index: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpandedIndex(isExpanded ? index : false); // Si es el acordeón abierto, se cierra; si no, se abre
  };

  return (
    <div className='menu_button_container'>
      {permissions.map((permission: any, index: number) => {
        return (
          <Accordion 
            key={index} 
            className='menuAccordion' 
            expanded={expandedIndex === index} 
            onChange={handleAccordionChange(index)} // Maneja el acordeón según su índice
          >
            <AccordionSummary 
              expandIcon={ <ExpandMoreIcon sx={{color: '#1D3B47'}} />}
              className='menuAccordionItem'
              sx={{
                backgroundColor: expandedIndex === index ? '#F6F6F7' : '#FFF', // Cambia el color cuando está abierto
                paddingRight: '5px !important'
              }}
            >
              <div className='menuCategory'>{permission.category}</div>
            </AccordionSummary>

            <AccordionDetails className='menuAccordionDetails'>
              <div className='submenu_container'>
                {permission.components.map((submenu: any, index2: number) => {
                  return (
                    <MenuButton 
                      key={submenu.path} 
                      label={submenu.label} 
                      to={submenu.path} 
                      startIcon={iconMap[submenu.icon]}
                    />     
                  );
                })}
              </div>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}

