import React from 'react';
import { Typography, Button, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { validateForm, ApiRequest } from 'GlobalFunctions';
import moment from 'moment';
import GetAppIcon from '@mui/icons-material/GetApp';

export default function PaymentResult(props: any) {
  const { open, setOpen, ltkn, callback, payment_id } = props;

  const exportPayment = async () => {
    await ApiRequest({
      url: '/payments/exportPayment',
      method: 'GET',
      headers: {
        ltkn: localStorage.getItem('ltkn'),
      },
      query: {
        payment_id,
      },
      setResponse: (response: any) => {
        const url = response.data;
        const title = `Recaudo_${moment().format('YYYY-MM-DD')}.pdf`;

        // Descargar el archivo
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', title);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      },
      setError: (error: any) => {
        console.error('Error al exportar el reporte:', error);
      },
    });
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Box sx={{ marginTop: 3 }}>

        <Box sx={{ marginTop: 3, display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="contained" color="primary" onClick={exportPayment} startIcon={<GetAppIcon />}>
            Exportar
          </Button>
          
        </Box>
      </Box>
    </Box>
  );
}
